import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Push Jerk 3×12`}</p>
    <p>{`DB Rows 3×12 (double arm)`}</p>
    <p>{`then,`}</p>
    <p>{`18:00 AMRAP of:`}</p>
    <p>{`18-Calorie Row`}</p>
    <p>{`18-T2B`}</p>
    <p>{`18-Alternating DB Hang Clean & Jerk (50/35`}{`#`}{`, 9/arm)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      